"use client";

import shuffle from "fisher-yates";
import { useState } from "react";
import { useWizard } from "react-use-wizard";
import { useStore } from "zustand";

import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";

import { useWordTaskStore } from "./provider";
import { type Word } from "./step";

interface QuizOption {
  word: string;
  correct: boolean;
  guessed: boolean;
}
export function QuizStep({ word }: { word: Word }) {
  const Store = useWordTaskStore();
  const task = useStore(Store, (s) => s.computed.task);
  const setAnswer = useStore(Store, (s) => s.setAnswer);
  const { nextStep } = useWizard();
  const [options, setOptions] = useState<QuizOption[]>(() => {
    const correctAnswers = word.right
      .trim()
      .split("\n")
      .map((w) => w.trim())
      .filter(Boolean);
    const wrongAnswers = shuffle(task.words)
      .map((w) => shuffle(w.right.split("\n").filter(Boolean))[0])
      .flat()
      .filter(Boolean)
      .filter((w) => !correctAnswers.includes(w!))
      .slice(0, 3) as string[];
    const correctAnswer = shuffle(correctAnswers).slice(0, 1);
    const allAnswers = shuffle([...correctAnswer, ...wrongAnswers]);
    return allAnswers.map((answer) => ({
      word: answer,
      correct: correctAnswers.includes(answer),
      guessed: false,
    }));
  });

  function handleGuess(index: number) {
    const newOptions = [...options];
    const option = newOptions[index];
    if (!option) {
      return;
    }
    option.guessed = true;
    setOptions(newOptions);
    if (option.correct) {
      setTimeout(() => {
        setAnswer({
          id: word.id,
          data: {
            answer: option.word,
            errors: newOptions.filter((o) => o.guessed && !o.correct).length,
          },
        });
        void nextStep();
      }, 1000);
    }
  }

  const hasGuessedCorrect = options.some((o) => o.guessed && o.correct);

  return (
    <div className="container grid grid-cols-2 gap-x-2 gap-y-4">
      {options.map((option, i) => {
        const variant = (() => {
          if (option.guessed && option.correct) {
            return "success";
          }
          if (option.guessed && !option.correct) {
            return "destructive";
          }
          return "outline";
        })();
        return (
          <Button
            key={i}
            type="button"
            disabled={option.guessed || hasGuessedCorrect}
            variant={variant}
            className={cn(
              "w-full transition-opacity duration-1000 disabled:pointer-events-none",
              {
                "disabled:opacity-0": option.guessed && !option.correct,
                "disabled:opacity-100": option.guessed && option.correct,
              },
            )}
            onClick={() => {
              handleGuess(i);
            }}
          >
            {option.word}
          </Button>
        );
      })}
    </div>
  );
}
