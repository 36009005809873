import { useStore } from "zustand";

import { Translation } from "~/components/translations/component";
import { Label } from "~/components/ui/label";
import { Switch } from "~/components/ui/switch";

import { useWordTaskStore } from "./provider";

export function Settings() {
  const Store = useWordTaskStore();
  const {
    flipped,
    setFlipped,
    deburred,
    setDeburred,
    ignoreWhiteSpace,
    setIgnoreWhiteSpace,
  } = useStore(Store, (s) => ({
    flipped: s.flipped,
    setFlipped: s.setFlipped,
    deburred: s.deburred,
    setDeburred: s.setDeburred,
    ignoreWhiteSpace: s.ignoreWhiteSpace,
    setIgnoreWhiteSpace: s.setIgnoreWhiteSpace,
  }));
  return (
    <div className="max-w-[500px] space-y-4">
      <h2 className="text-2xl font-bold">
        <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.title">
          Inställningar
        </Translation>
      </h2>
      <div className="flex flex-row items-center justify-between space-y-2 rounded-lg border p-4">
        <div className="space-y-0.5">
          <Label className="text-base font-medium">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.change-direction">
              Byt håll på språken
            </Translation>
          </Label>
          <p className="text-muted-foreground text-sm">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.change-direction-description">
              Istället för att se t ex svenska till engelska så ser du engelska
              till svenska.
            </Translation>
          </p>
        </div>
        <Switch
          className="ml-2"
          checked={flipped}
          onCheckedChange={(flipped) => setFlipped(flipped)}
        />
      </div>
      <div className="flex flex-row items-center justify-between space-y-2 rounded-lg border p-4">
        <div className="space-y-0.5">
          <Label className="text-base font-medium">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.ignore-accents">
              Ignorera accenter
            </Translation>
          </Label>
          <p className="text-muted-foreground text-sm">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.no-need-for">
              Du behöver inte skriva t ex
            </Translation>{" "}
            <strong>á</strong>{" "}
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.enough-with">
              utan det räcker med
            </Translation>{" "}
            <strong>a</strong>.
          </p>
        </div>
        <Switch
          className="ml-2"
          checked={deburred}
          onCheckedChange={(deburred) => setDeburred(deburred)}
        />
      </div>
      <div className="flex flex-row items-center justify-between space-y-2 rounded-lg border p-4">
        <div className="space-y-0.5">
          <Label className="text-base font-medium">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.ignore-spaces">
              Ignorera blanktecken
            </Translation>
          </Label>
          <p className="text-muted-foreground text-sm">
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.settings.ignore-spaces-description">
              Det gör inget om eventuella mellanslag eller radbrytningar skiljer
              sig från det korrekta svaret.
            </Translation>
          </p>
        </div>
        <Switch
          className="ml-2"
          checked={ignoreWhiteSpace}
          onCheckedChange={(ignoreWhiteSpace) =>
            setIgnoreWhiteSpace(ignoreWhiteSpace)
          }
        />
      </div>
    </div>
  );
}
