"use client";

import { DashboardHeader } from "~/components/header";

import { ClozeTaskView } from "./cloze-task";
import { type Props, Provider } from "./provider";

export const TaskCloze = ({ task, taskInstance, preview }: Props) => {
  return (
    <Provider ctx={{ task, preview }}>
      <div className="container">
        {!preview && <DashboardHeader heading={task.name}></DashboardHeader>}
      </div>
      <ClozeTaskView
        groupId={taskInstance.groupId}
        taskInstanceId={taskInstance.id}
        preview={!!preview}
      />
    </Provider>
  );
};
