"use client";

import { type ReactNode, createContext, useContext, useRef } from "react";
import { type z } from "zod";
import { createStore } from "zustand";

import { ClozeRoot, type Occlusion } from "~/server/zod/cloze";
import {
  type ClozeInsertSchema,
  type ClozeSelectSchema,
} from "~/server/zod/tasks";

import { type TaskProps } from "../shared";

interface Occlusion {
  index: number;
  label: string;
  completed: boolean;
  tries: number;
}

type ClozeTaskSchema = ClozeInsertSchema | ClozeSelectSchema;

interface ClozeTaskProps {
  task: ClozeTaskSchema;
  parsedClose: ClozeRoot;
}

export interface ClozeTaskState extends ClozeTaskProps {
  occlusions: Occlusion[];
  resetStore: () => void;
  preview: boolean;
  guessCloze: (targetOcclusionIndex: number, guess: string) => void;
}

interface ClozeTaskContext {
  preview?: boolean;
  task: ClozeTaskSchema;
}

type ClozeTaskStore = ReturnType<typeof createClozeTaskStore>;
const createClozeTaskStore = (ctx: ClozeTaskContext) => {
  const parsedClose = ClozeRoot.parse(
    typeof ctx.task.rawLexical === "string"
      ? JSON.parse(ctx.task.rawLexical)
      : ctx.task.rawLexical,
  );
  const _occlusions = parsedClose.root.children.flatMap((paragraph) =>
    paragraph.children.filter((child) => child.type === "occluded"),
  ) as z.infer<typeof Occlusion>[];

  const occlusions = _occlusions.map((child: z.infer<typeof Occlusion>, i) => ({
    index: i,
    label: child.text,
    completed: false,
    tries: 0,
  }));

  const DEFAULT_PROPS: ClozeTaskProps = {
    task: ctx.task,
    parsedClose,
  };
  return createStore<ClozeTaskState>()((set) => ({
    ...DEFAULT_PROPS,
    occlusions,
    task: ctx.task,
    preview: ctx.preview ?? false,

    resetStore: () => set((state) => ({ ...DEFAULT_PROPS, _task: state.task })),
    guessCloze: (targetOcclusionIndex, guess) =>
      set((state) => {
        const occlusion = state.occlusions[targetOcclusionIndex];
        if (!occlusion) return state;
        const completed = occlusion.label === guess;
        const tries = occlusion.tries + 1;
        const occlusions = state.occlusions.map((occlusion) => {
          if (occlusion.index === targetOcclusionIndex) {
            return { ...occlusion, completed, tries };
          }
          return occlusion;
        });
        return { ...state, occlusions };
      }),
  }));
};

export type Props = TaskProps<ClozeTaskSchema>;

export const ClozeTaskContext = createContext<ClozeTaskStore | null>(null);

export function Provider({
  children,
  ctx,
}: {
  children: ReactNode;
  ctx: ClozeTaskContext;
}) {
  const store = useRef(createClozeTaskStore(ctx)).current;
  return (
    <ClozeTaskContext.Provider value={store}>
      {children}
    </ClozeTaskContext.Provider>
  );
}

export function useClozeTaskStore() {
  const Store = useContext(ClozeTaskContext);
  if (!Store) throw new Error("Missing ClozeTaskContext.Provider in the tree");
  return Store;
}
