"use client";

import { type ReactNode, createContext, useContext } from "react";

import { type Task } from "~/payload-types";

import { TaskTypeEnum } from "../lib/baseTaskSettings";
import { baseTaskSettings } from "../lib/baseTaskSettings";
import { type MergedTaskSettings } from "./tasks/settings-fetcher";

export const TaskSettingsContext = createContext<MergedTaskSettings>(
  {} as MergedTaskSettings,
);

interface Props {
  children: ReactNode;
  taskSettings: Task[];
}

export function TaskSettingsProvider({ children, taskSettings }: Props) {
  const combinedTaskSettings = taskSettings.reduce((acc, task) => {
    const parsed = TaskTypeEnum.safeParse(task.id);
    if (!parsed.success) return acc;
    const baseTask = baseTaskSettings[parsed.data];
    acc[parsed.data] = {
      ...task,
      Icon: baseTask.Icon,
      resultZod: baseTask.resultZod,
      safeExamBrowser: baseTask.safeExamBrowser,
      badgeClassName: baseTask.badgeClassName,
    };
    return acc;
  }, {} as MergedTaskSettings);
  return (
    <TaskSettingsContext.Provider value={combinedTaskSettings}>
      {children}
    </TaskSettingsContext.Provider>
  );
}

export function useTaskSettingsStore() {
  const taskSettings = useContext(TaskSettingsContext);
  if (!taskSettings)
    throw new Error("Missing TaskSettingsContext.Provider in the tree");
  return taskSettings;
}
