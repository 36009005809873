"use client";

import { useState } from "react";
import { toast } from "sonner";
import { useStore } from "zustand";

import { LoadingButton } from "~/components/loading-button";
import { Translation } from "~/components/translations/component";
import { useTranslation } from "~/components/translations/provider";
import { Input } from "~/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { ResultSchema } from "~/lib/baseTaskSettings";
import { logger } from "~/lib/logger";
import { saveTaskInstanceResult } from "~/server/actions/tasks";

import { PostSubmit } from "../shared";
import { useWordTaskStore } from "./provider";

export function ExamView({
  groupId,
  taskInstanceId,
}: {
  groupId: number;
  taskInstanceId: string;
}) {
  const Store = useWordTaskStore();
  const { task, setAnswer, preview, answers } = useStore(Store, (s) => ({
    task: s.computed.task,
    answers: s.answers,
    setAnswer: s.setAnswer,
    preview: s.preview,
  }));
  const words = task.words;
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const genericErrorDescription = useTranslation("common.something-went-wrong");

  async function handleSubmit() {
    if (preview) {
      return;
    }
    setLoading(true);
    const res = ResultSchema.safeParse({
      type: "WORD",
      view: "EXAM",
      answers: Object.values(answers),
    });
    if (!res.success) {
      toast.error(genericErrorDescription);
      setLoading(false);
      logger.error(res.error);
      return;
    }
    await saveTaskInstanceResult({
      summary: res.data,
      taskInstanceId,
      groupId,
    });
    setDone(true);
    setLoading(false);
  }

  return (
    <div>
      {done ? (
        <PostSubmit groupId={groupId} preview={preview} isSeb taskType="EXAM" />
      ) : (
        <>
          <Table className="w-full">
            <TableHeader>
              <TableRow className="bg-secondary">
                <TableHead className="w-8"></TableHead>
                <TableHead className="text-foreground font-bold">
                  {task.leftTitle}
                </TableHead>
                <TableHead className="text-foreground font-bold">
                  {task.rightTitle}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {words.map((word, i) => (
                <TableRow key={word.id}>
                  <TableCell>
                    <div className="items-top flex">#{i + 1}</div>
                  </TableCell>
                  <TableCell>
                    {word.left.split("\n").map((word, i) => (
                      <span key={i} className="block whitespace-nowrap">
                        {word}
                      </span>
                    ))}
                  </TableCell>
                  <TableCell>
                    <Input
                      onChange={(e) =>
                        setAnswer({
                          id: word.id,
                          data: { word: e.target.value },
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <LoadingButton onClick={handleSubmit} isLoading={loading}>
            <Translation id="common.submit">Skicka in</Translation>
          </LoadingButton>
        </>
      )}
    </div>
  );
}
