"use client";

import { $getRoot } from "lexical";
import { AlertCircleIcon, FlagIcon } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

import { LexicalRender } from "~/components/blocks/RichText";
import { DashboardHeader } from "~/components/header";
import { DEFAULT_EMPTY_LEXICAL } from "~/components/lexical/editors/rich-text/default-lexical";
import { RichTextEditor } from "~/components/lexical/editors/rich-text/editor";
import { Translation } from "~/components/translations/component";
import { useTranslation } from "~/components/translations/provider";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import { Button } from "~/components/ui/button";
import { Label } from "~/components/ui/label";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "~/components/ui/resizable";
import { ResultSchema } from "~/lib/baseTaskSettings";
import { logger } from "~/lib/logger";
import { cn } from "~/lib/utils";
import { saveTaskInstanceResult } from "~/server/actions/tasks";
import {
  type AiGraderInsertTaskSchema,
  type AiGraderSelectSchema,
} from "~/server/zod/tasks";

import { PostSubmit, type TaskProps } from "./shared";

const MIN_CHAR_LIMIT = 50;

export type TaskAiGraderProps = TaskProps<
  AiGraderInsertTaskSchema | AiGraderSelectSchema
>;

export const TaskAiGrader = ({
  task,
  taskInstance,
  preview,
}: TaskAiGraderProps) => {
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const [studentTextRawLexical, setStudentTextRawLexical] = useState<string>(
    JSON.stringify(DEFAULT_EMPTY_LEXICAL),
  );
  const [studentText, setStudentText] = useState("");
  const [showInstructions, setShowInstructions] = useState(true);

  const hasEnoughChars = studentText.length >= MIN_CHAR_LIMIT;

  const genericErrorDescription = useTranslation("common.something-went-wrong");
  const enoughCharsText = useTranslation(
    "task.aigrader.enough-chars",
    "Skriv minst {limit} tecken för att skicka in.",
    {
      limit: MIN_CHAR_LIMIT,
    },
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (preview) {
      return;
    }
    if (!hasEnoughChars) {
      toast.error(enoughCharsText);
      return;
    }
    setLoading(true);
    const res = ResultSchema.safeParse({
      type: task.type,
      studentText,
      studentTextRawLexical,
    });
    if (!res.success) {
      toast.error(genericErrorDescription);
      logger.error(res.error);
      setLoading(false);
      return;
    }
    await saveTaskInstanceResult({
      summary: res.data,
      taskInstanceId: taskInstance.id,
      groupId: taskInstance.groupId,
    });
    setLoading(false);
    setDone(true);
  }

  return (
    <div className="mx-auto h-full w-full max-w-7xl pb-12">
      <form onSubmit={handleSubmit}>
        {!preview && (
          <DashboardHeader heading={task.name}>
            <Button disabled={done || loading || !hasEnoughChars} type="submit">
              <Translation id="common.turn-in">Lämna in</Translation>
            </Button>
          </DashboardHeader>
        )}
        {done ? (
          <PostSubmit
            groupId={taskInstance.groupId}
            preview={preview}
            taskType="AIGRADER"
          />
        ) : (
          <ResizablePanelGroup direction="horizontal">
            <ResizablePanel defaultSize={40} minSize={20}>
              <div className="px-2">
                <Label>
                  <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.instructions-for-task">
                    Instruktioner för uppgiften
                  </Translation>
                </Label>
                {task.taskTextRawLexical ? (
                  <LexicalRender content={task.taskTextRawLexical as unknown} />
                ) : (
                  <div className="prose-sm whitespace-pre-line">
                    {task.taskText}
                  </div>
                )}
              </div>
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel defaultSize={60} minSize={40}>
              <div className="grid gap-2 px-2">
                {!showInstructions && (
                  <div>
                    <Button
                      onClick={() => setShowInstructions(true)}
                      variant="ghost"
                      type="button"
                    >
                      <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.show-instructions">
                        Visa instruktioner
                      </Translation>
                    </Button>
                  </div>
                )}
                <div>
                  <Label htmlFor="student-text">
                    <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.your-text">
                      Din text
                    </Translation>
                  </Label>
                  <RichTextEditor
                    initialEditorState={studentTextRawLexical}
                    onChange={(editorState) => {
                      const plainText = editorState.read(() =>
                        $getRoot().getTextContent(),
                      );
                      setStudentText(plainText);
                      setStudentTextRawLexical(
                        JSON.stringify(editorState.toJSON()),
                      );
                    }}
                  />
                  {!hasEnoughChars && (
                    <p className="text-muted-foreground pt-2 text-xs">
                      <AlertCircleIcon className="mr-2 inline h-4 w-4" />
                      <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.write-at-least-prefix">
                        Skriv minst
                      </Translation>{" "}
                      {MIN_CHAR_LIMIT}{" "}
                      <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.write-at-least-suffix">
                        tecken för att skicka in.
                      </Translation>{" "}
                      <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.you-have-written-prefix">
                        Du har skrivit
                      </Translation>{" "}
                      <strong>{studentText.length}</strong>{" "}
                      <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.you-have-written-suffix">
                        tecken
                      </Translation>
                      .
                    </p>
                  )}
                </div>
                <div>
                  <AnonymousAlert />
                </div>
              </div>
            </ResizablePanel>
          </ResizablePanelGroup>
        )}
      </form>
    </div>
  );
};

function AnonymousAlert() {
  const [open, setOpen] = useState(false);
  return (
    <Alert>
      <FlagIcon className="h-4 w-4" />
      <AlertTitle>
        <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-title">
          Anonymisera texten
        </Translation>
      </AlertTitle>
      <AlertDescription>
        <div
          className={cn(
            "prose dark:prose-invert overflow-hidden transition-[max-height] duration-1000",
            {
              "max-h-20": !open,
              "fade-out-mask": !open,
              "max-h-[9999px]": open,
            },
          )}
        >
          <p>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-description-part-one">
              Nextgens utvecklare kan behöva titta på texten för att felsöka,
              analysera och ändra vår AI-funktionalitet. Vi kan aldrig se namnet
              på användare eller vilka skola ni går på - ni är anonyma. När ni
              skriver er text - anonymisera den också!
            </Translation>
          </p>

          <p>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-description-part-two">
              Er text kommer aldrig att &quot;kommas ihåg&quot; av vår AI, så
              även om du skulle skriva riktigt namn så händer ingenting.
            </Translation>
          </p>

          <p>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-description-place">
              Vill du skriva var du bor? Byt ut ditt ortsnamn till något av
              följande:
            </Translation>
          </p>
          <ul>
            <li>Nordsjöstad</li>
            <li>Silverbäck</li>
            <li>Granitkulle</li>
            <li>Björndal</li>
          </ul>

          <p>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-description-name">
              Vill du skriva vad du heter? Byt ut ditt riktiga namn mot någon av
              följande:
            </Translation>
          </p>

          <ul>
            <li>Erik</li>
            <li>Daniel</li>
            <li>Ali</li>
            <li>Ravi</li>
            <li>Anna</li>
            <li>Yasmin</li>
            <li>Maria</li>
            <li>Zara</li>
          </ul>

          <p>
            <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-aigrader.anonymize-text-description-school">
              Vill du berätta om din skola? Byt ut skolans namn mot någon av
              följande:
            </Translation>
          </p>
          <ul>
            <li>Solgårdens Gymnasium</li>
            <li>Västra Akademin</li>
            <li>Ängslyckans Grundskola</li>
          </ul>
        </div>
        <Button
          onClick={() => setOpen(!open)}
          variant="ghost"
          size="sm"
          type="button"
        >
          {open ? (
            <Translation id="common.show-less">Visa mindre</Translation>
          ) : (
            <Translation id="common.show-more">Visa mer</Translation>
          )}
        </Button>
      </AlertDescription>
    </Alert>
  );
}
