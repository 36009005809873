import { Separator } from "./ui/separator";
import { Skeleton } from "./ui/skeleton";

interface Props {
  heading: string | React.ReactNode;
  text?: string | React.ReactNode;
  children?: React.ReactNode;
}

export function DashboardHeader({ heading, text, children }: Props) {
  return (
    <div className="top-0 z-10 mb-2 bg-inherit md:mb-4">
      <div className="flex flex-col items-center justify-between gap-4 bg-inherit px-2 py-2 md:flex-row md:gap-32">
        <div className="grid gap-1">
          <h1 className="font-heading text-3xl md:text-4xl">{heading}</h1>
          {text && <p className="text-muted-foreground text-lg">{text}</p>}
        </div>
        {children}
      </div>
      <Separator />
    </div>
  );
}

DashboardHeader.Skeleton = function DashboardHeaderSkeleton({
  text,
  children,
}: Omit<Props, "heading">) {
  return (
    <div className="top-0">
      <div className="flex items-center justify-between px-2 py-2">
        <div className="grid gap-1">
          <h1 className="font-heading text-3xl md:text-4xl">
            <Skeleton className="h-10 w-2/5" />
          </h1>
          {text && <p className="text-muted-foreground text-lg">{text}</p>}
        </div>
        {children}
      </div>
      <Separator />
    </div>
  );
};
