"use client";

import { useMemo } from "react";
import { useStore } from "zustand";

import { Checkbox } from "~/components/ui/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";

import { useWordTaskStore } from "./provider";

export function WordTable() {
  const Store = useWordTaskStore();
  const task = useStore(Store, (s) => s.computed.task);
  const { pickedWords, setPickedWords } = useStore(Store, (s) => ({
    pickedWords: s.pickedWords,
    setPickedWords: s.setPickedWords,
  }));
  const words = task.words;
  const allChecked = useMemo(() => {
    if (words.every((word) => pickedWords.includes(word.id))) {
      return true;
    }
    if (pickedWords.length === 0) {
      return false;
    }
    return "indeterminate";
  }, [pickedWords, words]);
  return (
    <>
      <Table className="w-full">
        <TableHeader>
          <TableRow className="bg-secondary">
            <TableHead className="w-8">
              <div className="items-top flex">
                <Checkbox
                  checked={allChecked}
                  onCheckedChange={(state) => {
                    setPickedWords(state ? words.map((word) => word.id) : []);
                  }}
                />
              </div>
            </TableHead>
            <TableHead className="text-foreground font-bold">
              {task.leftTitle}
            </TableHead>
            <TableHead className="text-foreground font-bold">
              {task.rightTitle}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {words.map((word) => (
            <TableRow
              key={word.id}
              onClick={() => {
                if (pickedWords.includes(word.id)) {
                  setPickedWords(pickedWords.filter((id) => id !== word.id));
                } else {
                  setPickedWords([...pickedWords, word.id]);
                }
              }}
            >
              <TableCell>
                <div className="items-top flex">
                  <Checkbox checked={pickedWords.includes(word.id)} />
                </div>
              </TableCell>
              <TableCell>
                {word.left.split("\n").map((word, i) => (
                  <span key={i} className="block">
                    {word}
                  </span>
                ))}
              </TableCell>
              <TableCell>
                {word.right.split("\n").map((word, i) => (
                  <span key={i} className="block">
                    {word}
                  </span>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
