"use client";

import { type LucideIcon, PencilIcon, SquareStackIcon } from "lucide-react";
import { useStore } from "zustand";

import { Translation } from "~/components/translations/component";
import { cn } from "~/lib/utils";

import { type ViewName, useWordTaskStore } from "./provider";
import { Settings } from "./settings";
import { WordTable } from "./word-table";

const views: Record<
  ViewName,
  {
    name: React.ReactNode;
    Icon: LucideIcon;
  }
> = {
  SPELL: {
    name: <Translation id="task.word.spell">Skriv orden</Translation>,
    Icon: PencilIcon,
  },
  QUIZ: {
    name: <Translation id="task.word.quiz">Fyrvalsalternativ</Translation>,
    Icon: SquareStackIcon,
  },
};

export const NoViewSelected = () => {
  const Store = useWordTaskStore();
  const { view, setView } = useStore(Store, (s) => ({
    view: s.view,
    setView: s.setView,
  }));

  if (view) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="mx-auto grid w-full grid-cols-2 justify-center gap-2 md:grid-cols-4">
        {Object.entries(views).map(([viewName, view]) => (
          <button
            key={viewName}
            className={cn(
              "bg-background relative overflow-hidden rounded-lg border p-2 text-left",
            )}
            onClick={() => {
              setView(viewName as ViewName);
            }}
          >
            <div className="flex h-24 flex-col justify-around rounded-md p-2 md:h-32 md:p-6">
              <view.Icon className="h-8 w-8 md:h-12 md:w-12" />
              <div className="space-y-2">
                <h3 className="font-bold">{view.name}</h3>
              </div>
            </div>
          </button>
        ))}
      </div>
      <div className="flex auto-rows-auto grid-cols-5 flex-col gap-2 lg:grid">
        <div className="bg-background col-span-3 overflow-hidden rounded-xl border shadow-lg">
          <WordTable />
        </div>
        <div className="bg-background col-span-2 overflow-hidden rounded-xl border p-2 shadow-lg">
          <Settings />
        </div>
      </div>
    </div>
  );
};
