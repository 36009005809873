import { z } from "zod";

const Text = z.object({
  type: z.literal("text").default("text"),
  text: z.string(),
});

export const Occlusion = z.object({
  type: z.literal("occluded").default("occluded"),
  text: z.string(),
});

const Linebreak = z.object({
  type: z.literal("linebreak").default("linebreak"),
});

const Children = z.discriminatedUnion("type", [Text, Occlusion, Linebreak]);

const Paragraph = z.object({
  type: z.literal("paragraph").default("paragraph"),
  children: z.array(Children),
});

export const ClozeRoot = z.object({
  root: z.object({
    children: z.array(Paragraph),
    type: z.literal("root").default("root"),
  }),
});

export type ClozeRoot = z.infer<typeof ClozeRoot>;
