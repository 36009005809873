"use client";

import { stringDeburr } from "@zerodep/string-deburr";
import { type ReactNode, createContext, useContext, useRef } from "react";
import { type z } from "zod";
import { createStore } from "zustand";

import {
  type WordTaskInsertSchema,
  type WordTaskSelectSchema,
} from "~/server/zod/tasks";

import { type TaskProps } from "../shared";

interface Answer {
  id: string;
  data: Record<string, string | number | boolean>;
}
export type ViewName = "SPELL" | "QUIZ";

type WordTaskSchema = WordTaskSelectSchema | WordTaskInsertSchema;

interface WordTaskProps {
  _task: WordTaskSchema;
  answers: Record<string, Answer>;
  view?: ViewName;
  flipped: boolean;
  deburred: boolean;
  ignoreWhiteSpace: boolean;
  pickedWords: string[];
}

export interface WordTaskState extends WordTaskProps {
  setAnswer: (answer: Answer) => void;
  setView: (view: WordTaskProps["view"]) => void;
  resetStore: () => void;
  setFlipped: (flipped: boolean) => void;
  setDeburred: (deburred: boolean) => void;
  setIgnoreWhiteSpace: (ignoreWhiteSpace: boolean) => void;
  setPickedWords: (pickedWords: string[]) => void;
  preview: boolean;
  isExam: boolean;
  taskInstance: Props["taskInstance"];
  computed: {
    task: WordTaskSchema;
  };
}

interface WordTaskContext {
  taskInstance: Props["taskInstance"];
  preview?: boolean;
  task: WordTaskSchema;
  isExam?: boolean;
}

type WordTaskStore = ReturnType<typeof createWordTaskStore>;

const createWordTaskStore = (ctx: WordTaskContext) => {
  const DEFAULT_PROPS: Omit<WordTaskProps, "_task"> = {
    answers: {},
    view: undefined,
    deburred: false,
    flipped: false,
    ignoreWhiteSpace: false,
    pickedWords: [] as string[],
  };
  return createStore<WordTaskState>()((set, get) => ({
    ...DEFAULT_PROPS,
    _task: ctx.task,
    preview: ctx.preview ?? false,
    isExam: ctx.isExam ?? false,
    taskInstance: ctx.taskInstance,
    setAnswer: (answer) =>
      set((state) => {
        const newData = { answers: { ...state.answers, [answer.id]: answer } };
        return newData;
      }),
    setView: (view) =>
      set((state) => ({ _task: state._task, view, answers: {} })),
    setFlipped: (flipped) => set((state) => ({ ...state, flipped })),
    setDeburred: (deburred) => set((state) => ({ ...state, deburred })),
    setIgnoreWhiteSpace: (ignoreWhiteSpace) =>
      set((state) => ({ ...state, ignoreWhiteSpace })),
    setPickedWords: (pickedWords) =>
      set((state) => ({ ...state, pickedWords })),
    resetStore: () =>
      set((state) => ({ ...DEFAULT_PROPS, _task: state._task })),
    computed: {
      get task() {
        const flipped = get().flipped;
        const leftTitle = !flipped
          ? get()._task.leftTitle
          : get()._task.rightTitle;
        const rightTitle = !flipped
          ? get()._task.rightTitle
          : get()._task.leftTitle;
        const words = ctx.task.words
          .map((word) => {
            const left = flipped ? word.right : word.left;
            let right = flipped ? word.left : word.right;
            if (get().deburred) {
              right = stringDeburr(right);
            }
            return {
              ...word,
              left,
              right,
            };
          })
          .filter((word) => word.left && word.right);
        return {
          ...ctx.task,
          leftTitle,
          rightTitle,
          words,
        };
      },
    },
  }));
};

type Schema =
  | TaskProps<z.infer<typeof WordTaskSelectSchema>>
  | TaskProps<z.infer<typeof WordTaskInsertSchema>>;

export type Props = Schema & {
  isExam?: boolean;
};

export const WordTaskContext = createContext<WordTaskStore | null>(null);

export function Provider({
  children,
  ctx,
}: {
  children: ReactNode;
  ctx: WordTaskContext;
}) {
  const store = useRef(createWordTaskStore(ctx)).current;
  return (
    <WordTaskContext.Provider value={store}>
      {children}
    </WordTaskContext.Provider>
  );
}

export function useWordTaskStore() {
  const Store = useContext(WordTaskContext);
  if (!Store) throw new Error("Missing WordTaskContext.Provider in the tree");
  return Store;
}
